import React, { Component } from 'react';
import Message from '../Message/Message';
import firebase from 'firebase/app';
export default class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            message: '',
            photoUrl: '',
            date: '',
            list: [],
            loading: true
        };
        this.messageRef = firebase.database().ref().child('messages');
        this.listenMessages();
    }


    componentDidMount() {
        this.setState({ 'userName': this.props.user.displayName });

        var app = {
            htmlEl: {
                'chatForm': document.querySelector('.chat-form'),
                'chatInput': document.querySelector('.chat-form input'),
                'chatButton': document.querySelector('.chat-form button'),
                'userInfoel': document.querySelector('.chat-app .user-info'),
                'userInfoButton': document.querySelector('.chat-app .chat .profile-img'),
                '_chatWrapper': document.querySelector('.chat-app .chat-messages'),
                '_chatListEl': document.querySelector('.chat-app .chat-messages ul')
            },
            messageform: function () {

                this.htmlEl.chatInput.addEventListener('focus', function () {
                    app.htmlEl.chatForm.classList.add("active");
                    app.htmlEl._chatWrapper.scrollTop = app.htmlEl._chatWrapper.scrollHeight;
                });
                this.htmlEl.chatInput.addEventListener('focusout', function () {
                    if (app.htmlEl.chatInput.value === "") {
                        app.htmlEl.chatForm.classList.remove("active");
                    }
                });

                this.htmlEl.chatInput.addEventListener('keyup', function (e) {
                    if (app.htmlEl.chatInput.value.length > 0) {
                        app.htmlEl.chatForm.classList.add("typing");
                    } else {
                        app.htmlEl.chatForm.classList.remove("typing");
                    }

                    app.htmlEl._chatWrapper.scrollTop = app.htmlEl._chatWrapper.scrollHeight;
                });

                this.htmlEl.userInfoButton.addEventListener('click', function () {
                    app.htmlEl.userInfoel.classList.toggle("active");
                });

                document.querySelector('.messageCount').addEventListener('click', function () {
                    this.classList.remove('active');
                    document.querySelector('.chat-app .chat-messages').scrollTop = document.querySelector('.chat-app .chat-messages').scrollHeight;
                });

            },
            init: function () {
                this.messageform();

            }
        };
        app.init();

        // const nowTime = (new Date()).getHours() + ':' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds();
        // const nowDate = (new Date()).getDate() + '.' + ((new Date()).getMonth() + 1) + '.' + (new Date()).getFullYear();
        // console.log()
        // var userLog = {
        //     userName: this.props.user.displayName,
        //     message: "user logged",
        //     date: nowTime + ' - ' + nowDate,
        //     photoUrl: this.props.user.photoURL
        // }
        // this.messageRef.push(userLog);

    }


    handleChange(event) {
        this.setState({ message: event.target.value });
    }
    handleSend() {
        if (this.state.message) {
            const nowTime = (new Date()).getHours() + ':' + (new Date()).getMinutes() + ':' + (new Date()).getSeconds();
            const nowDate = (new Date()).getDate() + '.' + ((new Date()).getMonth() + 1) + '.' + (new Date()).getFullYear();
            var newItem = {
                userName: this.state.userName,
                message: this.state.message,
                date: nowTime + ' - ' + nowDate,
                photoUrl: this.props.user.photoURL
            }
            this.messageRef.push(newItem);
            this.setState({ message: '' });
        }
    }
    handleKeyPress(event) {
        if (event.key !== 'Enter') return;
        this.handleSend();
    }
    handleLogOut() {
        this.setState({ loading: true });
        firebase.auth().signOut();
    }
    listenMessages() {
        this.messageRef
            .limitToLast(200)
            .on('value', message => {
                if (message.val()) {
                    var oldMessageCount = this.state.list.length
                    this.setState({
                        list: Object.values(message.val())
                    });

                    if (oldMessageCount !== 0) {
                        if (oldMessageCount < this.state.list.length) {
                            if (document.querySelector('.chat-app .chat-messages').scrollTop < document.querySelector('.chat-app .chat-messages').scrollHeight) {
                                document.querySelector('.messageCount').classList.add('active');
                                setTimeout(function () {
                                    document.querySelector('.messageCount').classList.remove('active');
                                }, 1500);
                            }
                            document.querySelector('.chat-app .chat-messages').scrollTop = document.querySelector('.chat-app .chat-messages').scrollHeight;
                        }
                    } else {
                        document.querySelector('.chat-app .chat-messages').scrollTop = document.querySelector('.chat-app .chat-messages').scrollHeight;
                    }
                }
                this.setState({ loading: false });
            });
    }
    render() {
        return (

            <div className="chat-app">
                <div className="user-list">
                    <div className="container">
                        <div className="app_logo">

                        </div>
                        <button className="logout" onClick={this.handleLogOut.bind(this)}>
                            <svg version="1.1" viewBox="0 0 24 24">
                                <g id="info" />
                                <g id="icons">
                                    <g id="exit2">
                                        <path
                                            d="M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z" />
                                        <path
                                            d="M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="chat-messages">
                    <div className="container">
                        <ul>
                            {this.state.list.map((item, index) =>
                                <Message key={index} message={item} class={(this.props.user.displayName === item.userName) ? "me" : ""} />
                            )}
                        </ul>
                    </div>
                </div>
                <div className="chat">
                    <div className="container">
                        <div className="profile-img">
                            <img src={this.props.user.photoURL} alt="" />
                        </div>
                        <div className="chat-form">
                            <label>@message</label>
                            <input
                                type="text"
                                value={this.state.message}
                                onChange={this.handleChange.bind(this)}
                                onKeyPress={this.handleKeyPress.bind(this)}
                            />
                            <button onClick={this.handleSend.bind(this)}>
                                <svg version="1.1" viewBox="0 0 24 24">
                                    <g id="info" />
                                    <g id="icons">
                                        <path
                                            d="M21.5,11.1l-17.9-9C2.7,1.7,1.7,2.5,2.1,3.4l2.5,6.7L16,12L4.6,13.9l-2.5,6.7c-0.3,0.9,0.6,1.7,1.5,1.2l17.9-9   C22.2,12.5,22.2,11.5,21.5,11.1z"
                                            id="send" />
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <span className="messageCount">new</span>
                    </div>
                </div>
                <div className="user-info">
                    <div className="profile-settings">
                        <div className="accordion">
                            <h3>Profile</h3>
                            <div className="content">
                                <div className="field">
                                    <div className="profileImg">
                                        <img src={this.props.user.photoURL} alt="" />
                                    </div>
                                </div>
                                <div className="field">
                                    <label>@username</label>
                                    <input type="email" value={this.props.user.displayName} readOnly />
                                </div>
                                <div className="field">
                                    <label>@mail</label>
                                    <input type="email" value={this.props.user.email} readOnly />
                                </div>
                                <div className="submit-area">
                                    <button className="logout" onClick={this.handleLogOut.bind(this)}>
                                        <svg version="1.1" viewBox="0 0 24 24">
                                            <g id="info" />
                                            <g id="icons">
                                                <g id="exit2">
                                                    <path
                                                        d="M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z" />
                                                    <path
                                                        d="M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(this.state.loading) ? "loading active" : "loading"}>
                    <div className='peeek-loading'>
                        <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}