import React, { Component } from 'react';
import avatar from './../../assets/images/avatar.png';

export default class Message extends Component {
  render() {
    return (
      <li className={this.props.class + " active"}>
        <div className="message">
          <div className="info">
            <div className="img">
              <img src={this.props.message.photoUrl ? (
                this.props.message.photoUrl
              ):(
                avatar
              )} alt={this.props.message.userName} />
            </div>
            <span className="username">{this.props.message.userName}</span>
          </div>
          <div className="text">
            {this.props.message.message}
            <span className="date">{this.props.message.date}</span>
          </div>
        </div>
      </li>
    )
  }
}