import React, { Component } from 'react';
import './../../assets/styles/reset.css';
import './../../assets/styles/core.css';
import gmailIcon from './../../assets/images/gmail.svg';
import Form from '../Form/Form.js';
import firebase from 'firebase';
import firebaseConfig from '../../config';
firebase.initializeApp(firebaseConfig);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true
    }
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ user, loading: false });
    });
  }
  handleSignIn() {
    this.setState({ loading: true });
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider);
  }

  render() {
    return (
      <div className="master">
        {!this.state.user ? (
          <div className="login-wrapper">
            <div className="login-form">
              <h1>LOGIN</h1>
              <div className="app_logo">

              </div>
              <div className="submit-area">
                <button
                  className=""
                  onClick={this.handleSignIn.bind(this)}
                >
                  <img src={gmailIcon} alt="" />
                  Sign in
            </button>
              </div>
              <div className="link">
                <a href="https://www.umutyaldiz.com" target="_blank" rel="noopener noreferrer" className="link">
                  Copyright © 2019 Umut Yaldız
                    </a>
              </div>
            </div>
          </div>
        ) : (
            <Form user={this.state.user} />
          )}
        <div className={(this.state.loading) ? "loading active" : "loading"}>
          <div className='peeek-loading'>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
export default App;